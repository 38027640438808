/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */
export default () => Promise.resolve({
  info: {
    RTL: false,
    thousand_place: ',',
    currency_symbol: '€',
    fist_day_of_week: 7,
    // date_time_format: '|',
  },
  translations: {
    // ===api/interceptors/gotoLoginPageWhenNotAuthorized.js===
    'gotoLoginPageWhenNotAuthorized.js': {
      'Oops! You have been disconnected, because someone else logged into your account.': '¡Huy! Ha sido desconectado, porque alguien ha iniciado sesión en su cuenta.',
    },
    // ===api/index.js===
    'api/index.js': {
      'No internet connection': 'Sin conexión a Internet.',
      'Check your internet connection': 'Comprueba tu conexión a Internet.',
      'Oops! Something went wrong. Try closing and reopening your browser.': 'Oops! Something went wrong. Try closing and reopening your browser.',
      'The request which you sent has timeout.': 'La solicitud que ha enviado tiene tiempo de espera. ',
      'Please, wait few minutes and try again.': 'Por favor, espere unos minutos y vuelve a intentarlo.',
    },
    // ===components/ExecuteButton/ExecuteButton.jsx===
    'ExecuteButton.jsx': {
      'STOP': 'DETENER',
      'RUN': 'CORRER',
      'FINISHED': 'TERMINADO',
    },
    // ===components/Footer/Footer.jsx===
    'Footer.jsx': {
      'AUTONOMOUS WORKS LIMITED': 'AUTÓNOMA Works Limited 2019',
      'Help': 'Ayuda',
      'Privacy & Terms': 'Términos y privacidad',
    },
    // ===components/FormLogin/index.jsx===
    'FormLogin/index.jsx': {
      'E-mail not verified yet!': 'E-mail no ha sido verificada todavía!',
      'Login with Google': 'Ingresar con Google',
      'Login': 'Iniciar sesión',
    },

    // ===components/ImageUploader/ImageUploader.jsx===
    'ImageUploader.jsx': {
      'Files need to be smaller that 1MB': 'Los archivos tienen que ser menor que 1 MB',
      'Project category cover': 'cubierta categoría de proyecto',
      'Select or drag the image here': 'Seleccione o arrastre la imagen aquí',
    },

    // ===components/MediaExplorer/MediaItem.jsx===
    'MediaItem.jsx': {
      'Uploading': 'Subiendo',
    },

    // ===components/MediaExplorer/MediaLibrary.jsx===
    'MediaLibrary.jsx': {
      'Image library is empty': 'biblioteca de imágenes está vacía.',
      'Drag your image here to upload': 'Arrastre su imagen aquí para subir.',
    },
    // ===components/MediaExplorer/MediaModal.jsx===
    'MediaModal.jsx': {
      'This image will be removed from the library!': 'Esta imagen se elimina de la biblioteca!',
      'These {{number}} images will be removed from the library!': 'Estas {{number}} imágenes serán eliminados de la biblioteca!',
      'Are you sure?': '¿Estás seguro?',
      'Yes, delete it!': 'Sí, estoy seguro!',
      'Insert {{number}} images': 'Insertar {{number}} imágenes',
      'No image is selected.': 'se ha seleccionado ninguna imagen.',
      'Image Library': 'Biblioteca de imágenes',
      'Remove {{number}} image': 'retirar {{number}} imagen',
    },
    // ===components/NoData/NoData.jsx===
    'NoData.jsx': {
      'Oops... nothing here!': 'Uy ... nada aquí!',
    },

    'sensorsJSONGen.js': {
      'Digital / Analog': 'Digital / Analógico',
      'Bright LED': 'Bright LED',
      "4 LED RGB Strip(RGB-LED)": "Tira de 4 LED RGB(RGB-LED)",
      'Buzzer': 'Zumbador',
      'IR Emitter': 'IR Emitter',
      'Relay': 'Relé',
      'LED': 'LED',
      'Waterproof Temperature Sensor': 'Sensor de temperatura impermeable',
      'Temperature & Humidity Sensor': 'Temperatura y sensor de humedad',
      'IR Receiver': 'Receptor IR',
      'Button': 'Botón',
      'Event Mode': 'Modo de evento',
      'Continuous Mode': 'Modo continuo',
      'Magnetic - Hall Sensor': 'Magnético - Sensor de pasillo',
      'Vibration Sensor': 'Sensor de vibración',
      'Tilt Switch': 'Interruptor de inclinación',
      'Knock Sensor': 'Sensor de detonacion',
      'Touch Sensor': 'Sensor tactil',
      'PIR Motion Sensor': 'Sensor de movimiento PIR',
      'Collision Sensor': 'sensor de colisión',
      'Photo Electric Counter': 'Foto Contador eléctrico',
      'Switch': 'Cambiar',
      'Turn-on Event Mode': 'Modo de evento de encendido',
      'Turn-off Event Mode': 'Modo de evento de apagado',
      'IR Reflective Sensor': 'Sensor reflectante IR',
      'Rotary Angel Sensor': 'Sensor Ángel rotativa',
      'Moisture Sensor': 'Sensor de humedad',
      'Light Sensor': 'Sensor de luz',
      'Water Sensor': 'sensor de agua',
      'Air Quality Sensor': 'Sensor de calidad del aire',
      'Sound Sensor': 'sensor de sonido',
      "Slide Potentiometer": "Potenciómetro deslizante",
      'UV Sensor': 'sensor UV',
      'Pulse Sensor': 'sensor de pulso',
      'PH Sensor': 'sensor de pH',
      'MEMS Microphone': 'MEMS micrófono',
      'Serial': 'De serie',
      '3-Axis Digital Compass': '3 ejes brújula digital',
      'MPU6050 Accelerometer & Gyro': 'MPU6050 acelerómetro y Gyro',
      'OLED': 'OLED',
      'Color Sensor': 'sensor de color',
      'Laser Ranging Sensor': 'Sensor láser Ranging',
      '4-Digit Display': '4-Digit Dem',
      'LED Matrix': 'Matriz de LED',
      'I2C EEPROM': 'EEPROM I2C',
      'Gesture Sensor': 'Sensor de gestos',
      'BME280 Atmospheric Sensor': 'Sensor atmosférica BME280',
      'BMP180 Barometer': 'Barómetro BMP180',
      'Servo': 'servo',
      'Gripper': 'pinza',
      'Ultrasonic': 'Ultrasónico',
      'Ultrasonic Distance Sensor': 'La distancia del sensor ultrasónico',
    },

    // /Users/3dps/Documents/workspace/ui/src/components/SensorConfiguration/components/SensorWorkspace/Workspace/toolboxJSONGen.js
    'toolboxJSONGen.js': {
      'Bits': 'Pedacitos',
      'Sight': 'Visión',
      'Single LED': 'Solo LED',
      '8x8 LED Matrix': 'Matriz LED 8x8',
      '4-Digit Display': 'Pantalla de 4 dígitos',
      'OLED Display': 'Pantalla OLED',
      'Color Sensor': 'Sensor de color',
      'Sound': 'Sonido',
      'Buzzer': 'Zumbador',
      'Sound Sensor': 'Sensor de sonido',
      'Environmental': 'Ambiental',
      'Temperature & Humidity Sensor': 'Sensor de temperatura y humedad',
      'Light Sensor': 'Sensor de luz',
      'UV Sensor': 'Sensor UV',
      'Moisture Sensor': 'Sensor de humedad',
      'Water Sensor': 'Sensor de agua',
      'Waterproof Temperature Sensor': 'Sensor de temperatura a prueba de agua',
      'PH Sensor': 'Sensor de pH',
      'Air Quality Sensor': 'Sensor de calidad del aire',
      'BMP180 Barometer': 'Barómetro BMP180',
      'BME280 Atmospheric Sensor': 'Sensor atmosférico BME280',
      'Action': 'Acción',
      'IR Emitter': 'Emisor de infrarrojos',
      'IR Receiver': 'Receptor IR',
      'Relay': 'Relé',
      'Rotary Angle Sensor': 'Sensor de ángulo rotativo',
      'Linear Potentiometer': 'Potenciómetro lineal',
      'Photo Electric Counter': 'Photo Electric Counter',
      'Touch Sensor': 'Sensor tactil',
      'Button': 'Botón',
      'Switch': 'Cambiar',
      'IR Reflective Sensor': 'Sensor reflectante IR',
      'Collision Sensor': 'Sensor de colisión',
      'Tilt Switch': 'Interruptor de inclinación',
      'Pulse Sensor': 'Sensor de pulso',
      'PIR Motion Sensor': 'Sensor de movimiento PIR',
      'Laser Ranging Sensor': 'Sensor de rango láser',
      '3-Axis Digital Compass': 'Brújula digital de 3 ejes',
      'MPU6050 Accelerometer & Gyro': 'MPU6050 Acelerómetro y Gyro',
      'Magnetic - Hall Sensor': 'Magnético - Sensor de pasillo',
      'Vibration Sensor': 'Sensor de vibración',
      'Knock Sensor': 'Sensor de detonacion',
      'Gesture Sensor': 'Sensor de gestos',
      'I2C EEPROM': 'I2C EEPROM',
      'Servo': 'Servo',
      'Gripper': 'Pinza',
      'Distance': 'Distancia',
      'Ultrasonic Distance Sensor': 'Sensor de distancia ultrasónico',
      'Third Party': 'Tercero',
    },

    // ===components/SensorConfiguration/components/SensorWorkspace/index.jsx===
    'SensorWorkspace/index.jsx': {
      'Hardware sensor representation': 'representación sensor Hardware',
    },

    // ===components/SensorConfiguration/components/RobotItem.jsx===
    'RobotItem.jsx': {
      'Are you sure?': '¿Estás seguro?',
      'This robot will be removed from this class!': 'Este robot será retirado de esta clase!',
      'Yes, delete it!': 'Sí, estoy seguro!',
      'Oops! Error on saving your work': '¡Huy! Error en el ahorro de su trabajo',
      'Robot': 'Robot',
    },

    // ===components/SensorConfiguration/components/RobotsItems.jsx===

    'RobotsItems.jsx': {
      'Oops... no robots found!': 'Uy ... no hay robots encontrado!',
      'Please add at least one robot': 'Por favor, añadir al menos un robot',
    },

    // ===components/SensorConfiguration/SensorConfiguration.jsx===
    'SensorConfiguration.jsx': {
      'No robot were selected': 'No se seleccionaron robot',
      'Oops!': '¡Huy!',
      'Robot {{robotId}} is OFFLINE': 'Robot {{robotId}} fuera de linea',
      'SAVING...': 'AHORRO...',
      'ROBOT {{robotId}} SAVED': 'ROBOT {{robotId}} SALVADO',
      'SAVE CHANGES TO ROBOT {{robotId}}': 'Guardar cambios para ROBOT {{robotId}}',
      'Oops... no robots selected!': 'Uy ... no hay robots seleccionados!',
      'You must select one robot': 'Debe seleccionar un robot',
      'Robots': 'Robots',
    },

    // ===components/TopMenu/TopMenu.jsx===
    'components/TopMenu/TopMenu.jsx': {
      'Scan the class code with either the &#34;Kai&#39;s Eye&#34; phone': 'Escanear el código de clase, ya sea con el teléfono &#34;Ojo de Kai&#34;',
      'or': 'o',
      'using the &#34;Kai&#39;s Virtual&#34; viewer found in the app store.': 'utilizando el &#34;virtual de Kai&#34; espectador que se encuentra en la tienda de aplicaciones.',
      'Students': 'estudiantes',
      'My Robots': 'Mis Robots',
      'Robots Manager': 'Gestor de robots',
      'Add account': 'Añadir cuenta',
      'Switch Classroom': 'interruptor Aula',
      'Show Class Code': 'Mostrar Código de clase',
      'Logout': 'Cerrar sesión',
      'Privacy Policy': 'Política de privacidad',
      'About': 'Acerca de',
      'Version': 'Versión',
      'Disclaimer': 'Descargo de responsabilidad',
      'RTTTL ringtones files used are for personal enjoyment only.': 'RTTTL tonos de llamada archivos que se utilizan son sólo para el disfrute personal.',
      'All copyright belongs to its respective author.': 'Todos los derechos de autor pertenecen a su respectivo autor.',
      'Microsoft, Windows, and Windows Vista are either registered trademarks or trademarks of Microsoft Corporation in the United States and/or other': 'Microsoft, Windows y Windows Vista son marcas comerciales o marcas registradas de Microsoft Corporation en los Estados Unidos y / u otro',
      'App Store is a service mark of Apple Inc.': 'App Store es una marca de servicio de Apple Inc.',
      '“Android”, Google Play and Google Play logo are trademarks or registered trademarks of Google Inc.': '“Android”, Google Play y logotipo de Google Play son marcas comerciales o marcas comerciales registradas de Google Inc.',
      'Wi-Fi, the Wi-Fi CERTIFIED logo are trademarks or registered trademarks of Wi-Fi Alliance.': 'Wi-Fi, el logotipo de Wi-Fi CERTIFIED son marcas comerciales o marcas comerciales registradas de Wi-Fi Alliance.',
      'JAVASCRIPT is a registered trademark of Oracle and/or its affiliates.': 'JavaScript es una marca registrada de Oracle Corporation y / o sus filiales.',
      'Other names may be trademarks of their respective owners.': 'Otros nombres pueden ser marcas comerciales de sus respectivos propietarios.',
      'Autonomous Works LTD or Kai’s Clan is not associated with Oracle and Oracle does not sponsor or endorse Autonomous Works LTD or Kai’s Clan, its': 'Autónoma Works Ltd o clan de Kai no se asocia con Oracle y Oracle no patrocina o aprueba Autónoma Works Ltd o de Kai clan, su',
    },

    // ===routes/ClassRoom/components/MyProjects/NewWorkCard.jsx===
    'NewWorkCard.jsx': {
      'Save work': 'guardar el trabajo',
      'Awesome work': 'Impresionante trabajo',
      'Oops! Error on saving': '¡Huy! Error en el ahorro',
      'Save as new work': 'Guardar como nuevo trabajo',
    },

    // ===routes/ClassRoom/components/MyProjects/WorkCard.jsx===
    'WorkCard.jsx': {
      'Are you sure?': '¿Estás seguro?',
      'You won\'t be able to revert this! {{workName}} will be lost.': 'Usted no será capaz de revertir esto! {{workName}} se perderá.',
      'Yes, delete it!': 'Sí, estoy seguro!',
      'No, cancel!': 'No, cancelar!',
      'Removing ...': 'La eliminación de ...',
      'Created at': 'Crear en',
      'Updated at': 'Actualizado en',
    },

    // ===routes/ClassRoom/components/PathFindingEditor/PathFindingEditorModal.jsx===
    'PathFindingEditorModal.jsx': {
      'Pathfinding Editor': 'Editor de búsqueda de caminos',
      'Loading': 'Cargando',
    },

    // ===routes/ClassRoom/components/PathFindingEditor/PathFindingForm.jsx===
    'PathFindingForm.jsx': {
      'Your changes will be lost if you don\'t save them.': 'Los cambios se perderán si no los guarda.',
      'Don\'t save': 'No guardar',
      'Saved': 'Salvado',
      'There are some places have the same place name': 'Hay algunos lugares tienen el mismo nombre de lugar',
      'The pathfinding \'{{pathfindingName}}\' has been saved': 'la búsqueda de caminos {{pathfindingName}} ha sido salvado',
      'New place': 'Nuevo lugar',
      'Setted': 'Setted',
      'Select a Pathfinding file': 'Seleccione un archivo Pathfinding',
      'Awesome pathfinding': 'búsqueda de caminos impresionante',
      'Place X': 'lugar X',
      'Place Y': 'Y Place',
      'Place Name': 'Ponga su nombre',
    },

    // ===routes/ClassRoom/components/PathFindingEditor/PathFindingItem.jsx===
    'PathFindingItem.jsx': {
      'yours': 'tuyo',
    },

    // ===routes/ClassRoom/components/PathFindingEditor/PathFindingList.jsx===
    'PathFindingList.jsx': {
      'Select a Pathfinding file': 'Seleccione un archivo Pathfinding',
      'Add': 'Añadir',
    },

    // ===routes/ClassRoom/components/ProjectCategories/ProjectCategories.jsx===
    'ProjectCategories.jsx': {
      'Oops... no category found!': 'Uy ... ninguna categoría encontrado!',
      'Feel free to create one': 'Siéntase libre para crear una',
      'Ask the admin to add one': 'Solicitar al administrador añadir uno',
    },

    // ===routes/ClassRoom/components/ProjectCategories/ProjectCategoryItem.jsx===
    'ProjectCategoryItem.jsx': {
      'Are you sure?': '¿Estás seguro?',
      'You won\'t be able to revert this! {{categoryName}} will be lost.': 'Usted no será capaz de revertir esto! {{categoryName}} se perderá.',
      'Yes, delete it!': 'Sí, estoy seguro!',
      'No, cancel!': 'No, cancelar!',
      'Error removing project': 'proyecto de eliminación de errores',
      'Removing': 'Extracción',
      'Project category cover': 'cubierta categoría de proyecto',
      '{{number}} Projects': '{{number}} proyectos',
    },

    // ===routes/ClassRoom/components/ProjectList/ProjectCard.jsx===

    'ProjectCard.jsx': {
      'Are you sure?': '¿Estás seguro?',
      'You won\'t be able to revert this! {{projectName}} will be lost.': 'Usted no será capaz de revertir esto! {{projectName}} se perderá.',
      'Yes, delete it!': 'Sí, estoy seguro!',
      'No, cancel!': 'No, cancelar!',

      'Error removing project': 'proyecto de eliminación de errores',
      'Select a colour for this role': 'Seleccionar un color para este papel',
      'You need to write something!': 'Usted tiene que escribir algo!',
      'Error on add role to the project project': 'De error en función complemento al proyecto de proyecto',
      'Error on delete role': 'Error en la función de borrado',
      'Move {{projectName}} to category': 'Movimiento {{projectName}} a la categoría',
      'Select the category': 'Seleccione la categoría',
      'Error on move to projects': 'Error en la jugada de proyectos',
      'Project moved!': 'Proyecto pasó!',
      'The project was moved to new category.': 'El proyecto se trasladó a la nueva categoría.',
      'Removing ...': 'La eliminación de ...',
      'Access: This project only': 'Acceso: solamente Este proyecto',
      'Access: All projects': 'Acceso: Todos los proyectos',
      'created by': 'creado por',
      'Save': 'Salvar',
      'Create a new role': 'Crear un nuevo papel',
      'Move to category': 'Mover a la categoría',
    },

    // ===routes/ClassRoom/components/ProjectList/ProjectList.jsx===
    'ProjectList.jsx': {
      'Oops... no projects found!': 'Uy ... no hay proyectos encontrados!',
      'Feel free to create one': 'Siéntase libre para crear una',
      'All categories': 'Todas las categorias',
    },

    // ===routes/ClassRoom/components/SensorExcel/SensorExcel.jsx===
    'SensorExcel.jsx': {
      'Robot #{{robotId}} Sensors': 'Robot # {{robotId}} sensores',
      'Robot #{{robotId}} Positions': 'Robot # {{robotId}} posiciones',
      'Robot #{{robotId}} Battery': 'Robot # {{robotId}} Batería',
      'Oops: There are no robots seen by the camera.': 'Oops: No hay robots visto por la cámara.',
      'Switch on a robot and place on the mat.': 'Cambiar en un robot y lugar en el tapete.',
    },

    // ===routes/ClassRoom/components/SensorWidget/SensorWidget.jsx===
    'SensorWidget.jsx': {
      'Does not have any data yet': 'Todavía no tiene ningún dato',
    },

    // ===routes/ClassRoom/components/SensorWidget/SensorWidgetForS15.jsx===
    'SensorWidgetForS15.jsx': {
      'Temperature': 'Temperatura',
      'Humidity': 'Humedad',
    },

    // ===routes/ClassRoom/components/StatusMenuBar/components/ShareEmailForm.jsx===
    'ShareEmailForm.jsx': {
      'Please check "I\'m not a robot"': 'Por favor, compruebe "No soy un robot"',
    },

    // ===routes/ClassRoom/components/StatusMenuBar/StatusItemClassroomCode.jsx===
    'StatusItemClassroomCode.jsx': {
      'Scan the class code with either the &#34;Kai&#39;s Eye&#34; phone': 'Escanear el código de clase, ya sea con el teléfono &#34;Ojo de Kai&#34;',
      'or': 'o',
      'using the &#34;Kai&#39;s Virtual&#34; viewer found in the app store.': 'utilizando el &#34;virtual de Kai&#34; espectador que se encuentra en la tienda de aplicaciones.',
      'Share': 'Compartir',
      'Help': 'Ayuda',
    },

    // ===routes/ClassRoom/components/StatusMenuBar/StatusItemKaiBotsOfflineOnMat.jsx===
    'StatusItemKaiBotsOfflineOnMat.jsx': {
      'Offline - on Mat': 'Desconectado - en el fondo de',
    },

    // ===routes/ClassRoom/components/StatusMenuBar/StatusItemKaiBotsOnlineOffMat.jsx===
    'StatusItemKaiBotsOnlineOffMat.jsx': {
      'Online - off Mat': 'Online - off Mat',
    },

    // ===routes/ClassRoom/components/StatusMenuBar/StatusItemKaiBotsOnlineOnMat.jsx===
    'StatusItemKaiBotsOnlineOnMat.jsx': {
      'Online - on Mat': 'En línea - en el fondo de',
    },

    // ===routes/ClassRoom/components/StatusMenuBar/StatusItemKaisEyeAlignCamera.jsx===
    'StatusItemKaisEyeAlignCamera.jsx': {
      [`Kai's Eye`]: 'Ojo de Kai',
      'Align Camera': 'alinear la cámara',
    },

    // ===routes/ClassRoom/components/StatusMenuBar/StatusItemKaisEyeCameraOffline.jsx===
    'StatusItemKaisEyeCameraOffline.jsx': {
      'Camera Offline': 'cámara Desconectado',
    },

    // ===routes/ClassRoom/components/StatusMenuBar/StatusItemKaisEyeCameraOnline.jsx===
    'StatusItemKaisEyeCameraOnline.jsx': {
      'Camera Online': 'cámara Online',
    },

    // ===routes/ClassRoom/components/StatusMenuBar/StatusItemSensors.jsx===
    'StatusItemSensors.jsx': {
      '{{number}} Online': '{{number}} En línea',
    },

    // ===routes/ClassRoom/components/CameraStatusIcon.jsx===
    'CameraStatusIcon.jsx': {
      'The camera has been offline!': 'La cámara ha sido fuera de línea!',
      'Camera is online': 'La cámara está en línea',
      'Camera is offline': 'La cámara está desconectada',
    },

    // ===routes/ClassRoom/components/EditProjectModal.jsx===
    'EditProjectModal.jsx': {
      'Junior': 'Júnior',
      'Intermediate': 'Intermedio',
      'Advance': 'Avanzado',
      'Expert': 'Experto',
      'Project saved!': 'Proyecto salvado!',
      'was saved.': 'fue salvado.',
      'Error on save new project': 'Error al guardar nuevo proyecto',
      'Edit project - {{projectName}}': 'Editar proyecto - {{projectName}}',
      'Name your project': 'El nombre de su proyecto',
      'Category': 'Categoría',
      'Select category': 'Selecciona una categoría',
      'Map': 'Mapa',
      'Select map': 'Seleccionar mapa',
      'Select grade': 'Seleccione la calidad',
      'Enter a short summary of what this project is about.': 'Introduzca un breve resumen de lo que trata este proyecto.',
      'Cancel': 'Cancelar',
      'Save': 'Salvar',
    },

    // ===Help.jsx===
    'Help.jsx': {
      'Student': 'Estudiante',
      'Project Help': 'Ayuda proyecto',
      'Teacher': 'Profesor',
    },

    // ===HelpEditor.jsx===
    'HelpEditor.jsx': {
      'Explain how to play this game...': 'Explicar cómo jugar este juego ...',
      'No content': 'Sin contenido',
    },

    // ===Mat.jsx===
    'Mat.jsx': {
      'Out of camera view': 'Fuera de vista de la cámara',
      [`Ensure Kai's Eye app is running and it can view the entire mat. Don't cover the robot or mat QR markers.`]: 'Asegurar la aplicación de los ojos de Kai se está ejecutando y se puede ver toda la colchoneta. No cubra los marcadores QR robot o estera.',
      'The robot {{robotId}} has been online!': 'El robot {{robotId}} ha estado en línea!',
      'The robot {{robotId}} has been offline!': 'El robot {{robotId}} ha estado fuera de línea!',
      'student name': 'nombre del estudiante',
    },

    // ===NewCategoryModal.jsx===
    'NewCategoryModal.jsx': {
      'Add new category': 'Añadir nueva categoria',
      'Category saved!': 'Categoría salvado!',
      '{{fieldsName}} was saved.': '{{fieldsName}} fue salvado.',
      'Oops! Error on save category': '¡Huy! Error en la categoría Guardar',
      'Display on': 'Mostrar en',
      'Feature': 'Característica',
      'Community': 'Comunidad',
      'Awesome category': 'Categoría impresionante',
      'Category name': 'Nombre de la categoría',
      'Order': 'Orden',
      'Cover': 'Cubrir',
      'Cancel': 'Cancelar',
      'Add category': 'Añadir categoría',
    },

    // ===NewProjectModal.jsx===
    'NewProjectModal.jsx': {
      'Project saved!': 'Proyecto salvado!',
      '{{fieldsName}} was saved.': '{{fieldsName}} fue salvado.',
      'Oops! Error on save project': '¡Huy! Error en el proyecto SAVE',
      'Enter a short summary of what this project is about.': 'Introduzca un breve resumen de lo que trata este proyecto.',
      'Add project': 'Añadir proyecto',
      [`Kai's Projects`]: 'Proyectos de Kai',
      'Community Projects': 'Proyectos comunitarios',
      'Junior': 'Júnior',
      'Intermediate': 'Intermedio',
      'Advance': 'Avanzado',
      'Expert': 'Experto',
      'Add new project': 'Agregar nuevo proyecto',
      'Select category': 'Selecciona una categoría',
      'Select map': 'Seleccionar mapa',
      'Select grade': 'Seleccionar grado',
      'Cancel': 'Cancelar',
      'Project name': 'Nombre del proyecto',
      'Map': 'Mapa',
      'Level': 'Nivel',
      'Cover': 'Cubrir',
      'Description': 'Descripción',
      'Category': 'Categoría',
    },

    // ===NewUserGuideOverlay.jsx===
    'NewUserGuideOverlay.jsx': {
      'Code Blocks:': 'Los bloques de código:',
      'Here are all the blocks you will use to build your code.': 'Aquí están todos los bloques que\nusará para construir su código.',
      'Drag and drop these into the blue "Run" block to get started.': 'Arrastre y suelte estos en el\nbloque azul "Ejecutar" para comenzar.',

      'Workspace:': 'espacio de trabajo:',
      'This is the project you are currently accessing.': 'Este es el proyecto al que está accediendo actualmente.',

      'Projects:': 'proyectos:',
      'Click here to access all existing projects, or share your own!': 'Haga clic aquí para acceder a todos los proyectos existentes,\no comparte el tuyo!',

      'Views:': 'Puntos de vista:',
      'Access the Map, 3D view, text coding, console and help from here.': 'Acceda al mapa, vista 3D,\ncodificación de texto,\nconsola y ayuda desde aquí.',

      'Map:': 'Mapa:',
      'This is a real time view of your adventure mat, with bot positions and live sensor data.': 'Esta es una vista en tiempo real\nde tu alfombra de aventura,\ncon posiciones de bot\ny datos del sensor en vivo.',

      '3D View:': 'Vista 3D:',
      'Click here to load a 3D virtual view of your adventure mat!': 'Haga clic aquí para cargar\n¡una vista virtual en 3D de tu tapete de aventura!',

      'Code:': 'Código:',
      'This tab shows you what your blocks look like in text coding form': 'Esta pestaña te muestra qué\nsus bloques se ven en forma de codificación de texto',

      'Console:': 'Consola:',
      'Use the console to log data and messages, or debug your code.': 'Use la consola para registrar datos y mensajes,\no depurar tu código.',

      'Help:': 'Ayuda:',
      'Lesson plans and guides for both teachers and students can be found here.': 'Planes de lecciones y guías para ambos\nprofesores y estudiantes se pueden encontrar aquí.',

      [`Let's get coding'`]: 'Vamos a obtener la codificación!',

      'Kai In A Spaceship': 'Kai en una nave espacial',
    },

    // ===ProjectsModal.jsx===
    'ProjectsModal.jsx': {
      'Ooops! Error on saving sensors': 'Lamentablemente! Error en los sensores de ahorro',
      'My Projects': 'Mis proyectos',
      [`Kai's Projects`]: 'Proyectos de Kai',
      'Community': 'Comunidad',
      'Add project': 'Añadir proyecto',
      'Add category': 'Añadir categoría',
    },

    // ===StatusBar.jsx===
    'StatusBar.jsx': {
      'No Robot Selected': 'No robot seleccionado',
      'Robot {{robotId}}': 'robot # {{robotId}}',
    },

    // ===src/routes/ClassRoom/components/TopMenu.jsx===
    'src/routes/ClassRoom/components/TopMenu.jsx': {
      'PROJECT: {{title}}': 'PROYECTO: {{title}}',
      'TEMPORARY WORKSPACE:': 'ESPACIO DE TRABAJO TEMPORAL:',
    },

    // ===src/routes/ClassRoom/components/Views/help/ViewsHelpForTempWorkSpaceIntroductionOnStudent.jsx===
    'ViewsHelpForTempWorkSpaceIntroductionOnStudent.jsx': {
      'Document saved.': 'Documento guardado.',
    },

    // ===ViewsConsole.jsx===
    'ViewsConsole.jsx': {
      'Use the Print block to log messages to the console.': 'Utilice el bloque de impresión para registrar mensajes a la consola.',
      'CLEAR CONSOLE': 'CONSOLA LIMPIA',
      'TO THE LATEST': 'A la última',
    },

    // ===ViewsMap.jsx===
    'ViewsMap.jsx': {
      'Open Path Finding Mode': 'Modo Finding camino abierto',
      'Path Finding Mode is opened, click to close': 'Path modo diagnóstico se abre, haga clic para cerrar',
      'Export data to Microsoft Excel file': 'Exportar datos a archivo de Microsoft Excel',
    },

    // ===ViewsMap3D.jsx===
    'ViewsMap3D.jsx': {
      [`Click to open Kai's Clan Virtual Viewer or download it.`]: `Haga clic para abrir <u>Kai's Clan Virtual Viewer</u> o descargarlo.`,
      [`Kai's Clan Virtual Viewer is running in the new window.`]: `<u>Kai's Clan Virtual Viewer</u> se está ejecutando en la nueva ventana.`,
    },

    // ===ViewsView.jsx===
    'ViewsView.jsx': {
      // empty
    },

    // ===Workspace.jsx===
    'Workspace.jsx': {
      '{{number}} Result(s)': '{{number}} resultados',
      'No results found under NEWBIE': 'No se encontraron resultados bajo NEWBIE',
      'No results found under EXPERT': 'No se encontraron resultados bajo EXPERTO',
      'Close Search Result': 'Cerrar resultado de búsqueda',
      'Close': 'Cerrar',
      'Delete unused blocks': 'Eliminar los bloques no utilizados',
      'You will not be able to restore these unused blocks.': 'Usted no será capaz de restaurar estos bloques no utilizados.',
      'Yes': 'Sí',
      'Cancel': 'Cancelar',
    },

    // ===src/routes/ClassRoom/workspaces/basic/blocks/definition.js===

    'blocks/definition.js': {
      'func:robotArgByRole': {
        'Please associate a robot to me': 'Por favor asócieme un robot',
      },
      'func:getBoldText': {
        'read': 'leer',
        'set': 'establecer',
        'accurately': 'precisamente',
        'play': 'jugar',
        'reset': 'Reiniciar',
        'start': 'comienzo',
      },
      'Blockly.Blocks.setConfig': {
        '(Student Read-Only)': '(Estudiante de solo lectura)',
        'Set classroom variable': 'Establecer variable de aula',
        'None variable created': 'Ninguna variable creada',
      },

      'Blockly.Blocks.getConfig': {
        '(Read-Only)': '(Solo lectura)',
        'Classroom': 'Salón de clases',
        'value': 'valor',
      },

      'Blockly.Blocks.set_classroom_variable': {
        '(Student Read-Only)': '(Estudiante de solo lectura)',
        'Set classroom variable': 'Establecer variable de aula',
        'None variable created': 'Ninguna variable creada',
      },
      'Blockly.Blocks.get_classroom_variable': {
        '(Read-Only)': '(Solo lectura)',
        'Classroom': 'Salón de clases',
        'value': 'valor',
      },

      'Blockly.Blocks.runProgram': {
        'Run': 'correr',
        'Loop': 'Lazo',
        'Events': 'Eventos',
      },

      'Blockly.Blocks.motion_move__set_gear': {
        'Bot %1 set speed to %2': 'Larva del moscardón %1 ajuste de la velocidad de %2',
        'slow': 'lento',
        'normal': 'normal',
        'fast': 'rápido',
        'fastest': 'lo más rápido',
      },

      'Blockly.Blocks.motion_move__move_forwards_distance': {
        'Bot %1 move forward %2 cm': 'Larva del moscardón %1 avanzar %2 cm',
      },

      'Blockly.Blocks.motion_move__move_backwards_distance': {
        'Bot %1 move backward %2 cm': 'Larva del moscardón %1 mover hacia atrás %2 cm',
      },

      'Blockly.Blocks.motion_angle__turn_left': {
        'Bot %1 turn left by %2 degrees': 'Larva del moscardón %1 gire a la izquierda por %2 grados',
      },

      'Blockly.Blocks.motion_angle__turn_right': {
        'Bot %1 turn right by %2 degrees': 'Larva del moscardón %1 gire a la derecha por %2 grados',
      },

      'Blockly.Blocks.motion_angle__change_to': {
        'Bot %1 set angle to %2 degrees': 'Larva del moscardón %1 conjunto ángulo a %2 grados',
      },

      'Blockly.Blocks.motion_move__move_to_position_obj': {
        'Bot %1 move to %2': 'Larva del moscardón %1 mover a %2',
      },

      'Blockly.Blocks.motion_move__stopXYObjWithDistance': {
        'Bot %1 move to %2, stop %3 cm away': 'Larva del moscardón %1 mover a %2, detener %3 cm de distancia',
      },

      'Blockly.Blocks.sensing__targetXYObj': {
        '%1 position X: %2 Y: %3 cm': '%1 posición X: %2 Y: %3 cm',
      },

      'Blockly.Blocks.sensing__robotXYAObj': {
        '%1 position of Bot %2': '%1 posición de Bot %2',
      },

      'Blockly.Blocks.sensing__mapMarkXYObj': {
        '%1 position of map mark': '%1 la posición de la marca de mapa',
      },

      'Blockly.Blocks.sensing__distanceOfPositionXYObj': {
        'distance (cm) between %1 and %2': 'distancia (cm) entre %1 y %2',
      },

      'Blockly.Blocks.motion_move__go_to_place': {
        'Bot %1 navigate to %2': 'Larva del moscardón %1 navegar a %2',
      },

      'Blockly.Blocks.motion_move__go_to_place_with_input_name': {
        'Bot %1 navigate to %2': 'Larva del moscardón %1 navegar a %2',
      },

      'Blockly.Blocks.motion_move__go_to_XYPosition_by_the_pathfinding': {
        'Bot %1 navigate to %2': 'Larva del moscardón %1 navegar a %2',
      },

      'Blockly.Blocks.motion_move__move_to_XY_obj_with_self_adjustment': {
        'Bot %1 %2 move to %3': 'Larva del moscardón %1 %2 mover a %3',
      },

      'Blockly.Blocks.motion_move__move_to_XY_obj_stop_distance_with_self_adjustment': {
        'Bot %1 %2 move to %3, stop at distance %4 cm': 'Larva del moscardón %1 %2 mover a %3, Parada en distancia %4 cm',
      },

      'Blockly.Blocks.motion_move__grabObjAtXY': {
        'Bot %1 grab object at %2': 'Larva del moscardón %1 agarrar objetos en %2',
      },

      'Blockly.Blocks.motion_move__releaseObjAtXY': {
        'Bot %1 release object at %2': 'Larva del moscardón %1 liberar objeto en %2',
      },

      'Blockly.Blocks.motion_follow__stopXYObjWithDistance': {
        'Bot %1 follow %2, go %3 cm': 'Larva del moscardón %1 seguir %2, vaya %3 cm',
      },

      'Blockly.Blocks.motion_move__stop': {
        'Bot %1 stop moving (Event)': 'Larva del moscardón %1 dejar de moverse (Event)',
      },

      'Blockly.Blocks.motion_special__circle': {
        'Bot %1 drive %2 in circle, diameter %3cm, central angles %4°': 'Larva del moscardón %1 manejar %2 en círculo, diámetro %3cm, ángulos centrales %4°',
        'left': 'Izquierda',
        'right': 'Correcto',
      },

      'Blockly.Blocks.motion_servo__open_close_toggle': {
        'Bot %1 %2 ⛵ Gripper open-status %3': 'Larva del moscardón %1 %2 ⛵ Pinza abierta %3',
      },

      'Blockly.Blocks.sensing__gripper': {
        'Bot %1 %2 ⛵ Gripper open-status (true/false)': 'Larva del moscardón %1 %2 ⛵ Pinza abierta (verdadero / falso)',
      },

      'Blockly.Blocks.sensing__ultrasonic_distance_sensor': {
        'Bot %1 %2 ⛵ Ultrasonic Sensor distance (mm)': 'Larva del moscardón %1 %2 distancia del sensor ultrasónico ⛵ (mm)',
      },

      'Blockly.Blocks.sound_board_buzzer_play__sound': {
        'Bot %1 ⛵ Buzzer %2 %3 at %4 %% volume': 'Larva del moscardón %1 ⛵ zumbador %2 %3 a %4 %% volumen',
      },

      'Blockly.Blocks.leddiffuser_set_colorObj': {
        'Bot %1 %2 ⛵ %3 Headlight(s) to %4': 'Larva del moscardón %1 %2 ⛵ %3 Faro (s) a %4',
        'Left': 'Izquierda',
        'Right': 'Correcto',
        'Both': 'Ambos',
      },

      'Blockly.Blocks.leddiffuser_blink': {
        'Bot %1 %2 ⛵ Headlights blink every %3 ms, duty %4 %%, brightness %5 %%': 'Larva del moscardón %1 %2 ⛵ Faros parpadeo cada %3 ms, el deber %4 %%, brillo %5 %%',
      },

      'Blockly.Blocks.leddiffuser_blink_swap': {
        'Bot %1 %2 ⛵ Headlights swap color %3': 'Larva del moscardón %1 %2 ⛵ Faros de color de intercambio %3',
      },

      'Blockly.Blocks.sound_ext_buzzer_play__sound': {
        'Bot %1 🔌Add-on Buzzer %2 %3 at %4 %% volume': 'Larva del moscardón %1 🔌Add-en zumbador %2 %3 a %4 %% volumen',
      },

      'Blockly.Blocks.sensing__soundRTTTLListObj': {
        '%1 Song %2': '🎵 %1 Canción %2',
      },

      'Blockly.Blocks.sensing__soundRTTTLCommandObj': {
        '%1 RTTTL %2 %3': '%1 RTTTL %2 %3',
        'RTTTL rhythm is too long !': 'RTTTL canción es demasiado largo!',
      },

      'Blockly.Blocks.sensing__soundBeepObj': {
        '%1 Buzz, frequency %2 Hz, duration %3 ms': '🎵 %1 Zumbido, la frecuencia %2 Hz, duración %3 Sra',
      },

      'Blockly.Blocks.sensing__Sound_Sensor': {
        'Bot %1 %2 🔌Add-on Sound Sensor sound level (%)': 'Larva del moscardón %1 %2 🔌Add-en el nivel de sonido del sensor de sonido (%)',
      },

      'Blockly.Blocks.sensing__colorPickerObj': {
        '%1 color %2': '🚦 %1 color %2',
      },

      'Blockly.Blocks.sensing__colorRGBObj': {
        '%1 color Red %2 Green %3 Blue %4': '🚦 %1 color rojo %2 Verde %3 Azul %4',
      },

      'Blockly.Blocks.sensing__Color_Sensor': {
        '%1 Bot %2 %3 🔌Add-on Color Sensor': '🚦 %1 Larva del moscardón %2 %3 🔌Add-de sensor de color',
      },

      'Blockly.Blocks.sight_led_white__adjust_brightness': {
        'Bot %1 %2 🔌Add-on Single LED brightness to %3 %%': 'Larva del moscardón %1 %2 🔌Add-en Individual brillo del LED a %3 %%',
      },

      'Blockly.Blocks.sight_led_white__blink': {
        'Bot %1 %2 🔌Add-on Single LED blink %3 times every 5s': 'Larva del moscardón %1 %2 🔌Add-en abrir y cerrar Individual LED %3 veces cada 5s',
      },

      'Blockly.Blocks.sensing__Bright_LED': {
        'Bot %1 %2 🔌Add-on Single LED brightness (%%)': 'Larva del moscardón %1 %2 🔌Add-en brillo único LED (%%)',
      },

      'Blockly.Blocks.sight_led_strip__adjust_all_color': {
        'Bot %1 %2 🔌Add-on RGB-LED color at all positions to %3': 'Larva del moscardón %1 %2 🔌Add-en el color RGB LED en todas las posiciones a %3',
      },

      'Blockly.Blocks.sight_led_strip__adjust_index_color': {
        'Bot %1 %2 🔌Add-on RGB-LED color at position %3 to %4': 'Larva del moscardón %1 %2 color en 🔌Add RGB LED en la posición %3 a %4',
      },

      'Blockly.Blocks.sight_led_strip__adjust_all_brightness': {
        'Bot %1 %2 🔌Add-on RGB-LED all lights brightness to %3 %%': 'Larva del moscardón %1 %2 🔌Add-en RGB LED-todas las luces de brillo para %3 %%',
      },

      'Blockly.Blocks.sight_led_strip__adjust_index_brightness': {
        'Bot %1 %2 🔌Add-on RGB-LED light at position %3 , brightness to %4 %%': 'Larva del moscardón %1 %2 luz-en 🔌Add RGB LED en la posición %3 , El brillo de %4 %%',
      },

      'Blockly.Blocks.sight_led_strip__light_mode': {
        'Bot %1 %2 🔌Add-on RGB-LED %3, animate %4 times every 5s': 'Larva del moscardón %1 %2 -En 🔌Add RGB LED %3, animar %4 veces cada 5s',
        'right scroll': 'desplazamiento a la derecha',
        'left scroll': 'desplazamiento a la izquierda',
        'mirror flip': 'flip espejo',
        'on/off blink': 'de encendido / apagado abrir y cerrar',
      },

      'Blockly.Blocks.sight_led_strip__reset': {
        'Bot %1 %2 🔌Add-on RGB-LED to default': 'Larva del moscardón %1 %2 🔌Add-en RGB-LED por defecto',
      },

      'Blockly.Blocks.sensing__led_strip__read_rgb_by_index_number': {
        '%1 Bot %2 %3 🔌Add-on RGB-LED color at position %4': '🚦 %1 Larva del moscardón %2 %3 color en 🔌Add RGB LED en la posición %4',
      },

      'Blockly.Blocks.sight_4digi_tube__display_all_4digi': {
        'Bot %1 %2 🔌Add-on 4-Digit Display to %3 %4 : %5 %6': 'Larva del moscardón %1 %2 🔌Add-en 4 dígitos Display a %3 %4 : %5 %6',
      },

      'Blockly.Blocks.sight_4digi_tube__display_index_4digi': {
        'Bot %1 %2 🔌Add-on 4-Digit Display number at position %3 to %4': 'Larva del moscardón %1 %2 🔌Add-sobre el número de pantalla de 4 dígitos en la posición %3 a %4',
      },

      'Blockly.Blocks.sight_4digi_tube__display_time': {
        'Bot %1 %2 🔌Add-on 4-Digit Display to MM:SS %3 : %4': 'Larva del moscardón %1 %2 🔌Add-en 4 dígitos Display a MM: SS %3 : %4',
      },

      'Blockly.Blocks.sight_4digi_tube__display_time_countdown': {
        'Bot %1 %2 🔌Add-on 4-Digit Display to MM %3 : SS %4 and start to countdown ⏰': 'Larva del moscardón %1 %2 🔌Add-en 4 dígitos Display a MM %3 : SS %4 y empezar la cuenta atrás ⏰',
      },

      'Blockly.Blocks.sight_4digi_tube__enable_index': {
        'Bot %1 %2 🔌Add-on 4-Digit Display enable-status at position %3 to %4': 'Larva del moscardón %1 %2 🔌Add-en-4 digitos enable-estado en la posición %3 a %4',
      },

      'Blockly.Blocks.sight_4digi_tube__set_brightness': {
        'Bot %1 %2 🔌Add-on 4-Digit Display brightness to %3': 'Larva del moscardón %1 %2 🔌Add-en-4 Digit brillo de la pantalla %3',
      },

      'Blockly.Blocks.sensing__The_4_Digit_Display': {
        'Bot %1 %2 🔌Add-on 4-Digit Display value at position %3': 'Larva del moscardón %1 %2 🔌Add-en el valor de pantalla de 4 dígitos en la posición %3',
      },

      'Blockly.Blocks.sight_led_matrix_display__show': {
        'Bot %1 %2 🔌Add-on LED Matrix to display %3': 'Larva del moscardón %1 %2 🔌Add-en matriz LED para mostrar %3',
      },

      'Blockly.Blocks.sight_led_matrix_display__set_text': {
        'Bot %1 %2 🔌Add-on LED Matrix, display %3': 'Larva del moscardón %1 %2 🔌Add-en matriz LED, pantalla %3',
      },

      'Blockly.Blocks.sight_led_matrix_display__set_progress': {
        'Bot %1 %2 🔌Add-on LED Matrix, to display progress bar of %3 %%': 'Larva del moscardón %1 %2 🔌Add-Matriz de LED, para mostrar la barra de progreso de %3 %%',
      },

      'Blockly.Blocks.sight_led_matrix_display__set_brightness': {
        'Bot %1 %2 🔌Add-on LED Matrix brightness to %3': 'Larva del moscardón %1 %2 🔌Add-en matriz LED de brillo para %3',
      },

      'Blockly.Blocks.sensing__led_matrix_display': {
        '░ Bot %1 %2 🔌Add-on LED Matrix pixels': '░ Bot %1 %2 🔌Add-en los píxeles de la matriz LED',
      },

      'Blockly.Blocks.sensing__led_matrix_display_constant': {
        '░ 8x8 matrix of %1': '░ matriz 8x8 de %1',
      },

      'Blockly.Blocks.sensing__led_matrix_display_pixels': {
        'LED matrix pixels:': '░ píxeles de la matriz LED:',
      },

      'Blockly.Blocks.sensing__Light_Sensor': {
        'Bot %1 %2 🔌Add-on Light Sensor (%)': 'Larva del moscardón %1 %2 🔌Add-en el sensor de luz (%)',
      },

      'Blockly.Blocks.sensing__humidity_sensor': {
        'Bot %1 %2 🔌Add-on Temperature & Humidity Sensor %3': 'Larva del moscardón %1 %2 🔌Add-en temperatura y humedad Sensor %3',
        'Temperature (℃)': 'Temperatura (℃)',
        'Humidity (%)': 'Humedad (%)',
      },

      'Blockly.Blocks.sensing__UV_Sensor': {
        'Bot %1 %2 🔌Add-on UV Sensor (%)': 'Larva del moscardón %1 %2 🔌Add-en Sensor UV (%)',
      },

      'Blockly.Blocks.sensing__Moisture_Sensor': {
        'Bot %1 %2 🔌Add-on Moisture Sensor (%)': 'Larva del moscardón %1 %2 🔌Add-en sensor de humedad (%)',
      },

      'Blockly.Blocks.sensing__Water_Sensor': {
        'Bot %1 %2 🔌Add-on Water Sensor (%)': 'Larva del moscardón %1 %2 🔌Add-en Sensor de agua (%)',
      },

      'Blockly.Blocks.sensing__Waterproof_Temperature_Sensor': {
        'Bot %1 %2 🔌Add-on Waterproof Temperature Sensor (℃)': 'Larva del moscardón %1 %2 🔌Add-en sensor de temperatura de a prueba de agua (℃)',
      },

      'Blockly.Blocks.sensing__PH_Sensor': {
        'Bot %1 %2 🔌Add-on PH Sensor (pH value)': 'Larva del moscardón %1 %2 🔌Add-en sensor de pH (valor pH)',
      },

      'Blockly.Blocks.sensing__BMP180_Barometer': {
        'Bot %1 %2 🔌Add-on BMP180 Barometer (kPa)': 'Larva del moscardón %1 %2 🔌Add-en BMP180 barómetro (kPa)',
      },

      'Blockly.Blocks.sensing__BME280_Atmospheric_Sensor': {
        'Bot %1 %2 🔌Add-on Atmospheric Sensor %3': 'Larva del moscardón %1 %2 🔌Add-de sensor atmosférica %3',
        'air pressure(kPa)': 'presión de aire (kPa)',
        'humidity (%)': 'Humedad (%)',
        'temperature (℃)': 'temperatura (℃)',
      },

      'Blockly.Blocks.irTransmitter': {
        'Bot %1 %2 🔌Add-on IR Emitter to send %3': 'Larva del moscardón %1 %2 🔌Add-en emisor de infrarrojos para enviar %3',
      },

      'Blockly.Blocks.sensing__irReceiver': {
        '📡 %1 %2 🔌Add-on IR Receiver lasted raw code': '📡 %1 %2 🔌Add-en receptor de infrarrojos duró código crudo',
      },

      'Blockly.Blocks.sensing__irRawCode': {
        '📡 IR remote raw code %1': '📡 IR remota de código en bruto %1',
      },

      'Blockly.Blocks.relaySwitch': {
        'Bot %1 %2 🔌Add-on Relay on-status %3': 'Larva del moscardón %1 %2 🔌Add-en-relé de estado %3',
      },

      'Blockly.Blocks.sensing__relay': {
        'Bot %1 %2 🔌Add-on Relay on-status (true/false)': 'Larva del moscardón %1 %2 🔌Add-en-relé de estado (verdadero / falso)',
      },

      'Blockly.Blocks.sensing__Rotary_Angel_Sensor': {
        'Bot %1 %2 🔌Add-on Rotary Angel Sensor (degree)': 'Larva del moscardón %1 %2 🔌Add-a Ángel sensor rotatorio (grado)',
      },

      'Blockly.Blocks.sensing__Linear_Potentiometer': {
        'Bot %1 %2 🔌Add-on Linear Potentiometer (%)': 'Larva del moscardón %1 %2 🔌Add-en Linear potenciómetro (%)',
      },

      'Blockly.Blocks.Sensor_Pulse_Sensor_enable': {
        'Bot %1 %2 🔌Add-on Pulse Sensor Detection': 'Larva del moscardón %1 %2 🔌Add-en la detección del sensor de pulso',
      },

      'Blockly.Blocks.Sensor_Photo_Electric_Counter_reset': {
        'Bot %1 %2 🔌Add-on Photo Electric Counter': 'Larva del moscardón %1 %2 🔌Add-Foto de Contador eléctrico',
      },

      'Blockly.Blocks.sensing__Photo_Electric_Counter': {
        'Bot %1 %2 🔌Add-on Photo Electric Counter': 'Larva del moscardón %1 %2 🔌Add-Foto de Contador eléctrico',
      },

      'Blockly.Blocks.sensing__Touch_Sensor': {
        'Bot %1 %2 🔌Add-on Touch Sensor touch-status (true/false)': 'Larva del moscardón %1 %2 🔌Add-en-Touch Sensor táctil de estado (verdadero / falso)',
      },

      'Blockly.Blocks.sensing__Push_Button': {
        'Bot %1 %2 🔌Add-on Button pressed-status (true/false)': 'Larva del moscardón %1 %2 🔌Add-en-Botón de estado presionado (verdadero / falso)',
      },

      'Blockly.Blocks.sensing__Switch': {
        'Bot %1 %2 🔌Add-on Switch on-status (true/false)': 'Larva del moscardón %1 %2 🔌Add-en-Encender el estado (verdadero / falso)',
      },

      'Blockly.Blocks.sensing__ir_reflective_sensor': {
        'Bot %1 %2 🔌Add-on IR Reflective Sensor detected-status (true/false)': 'Larva del moscardón %1 %2 🔌Add-en sensor reflectante IR-estado detectado (verdadero / falso)',
      },

      'Blockly.Blocks.sensing__limit_switch': {
        'Bot %1 %2 🔌Add-on Collision Sensor closed-status (true/false)': 'Larva del moscardón %1 %2 🔌Add-en sensor de colisión cerrado de estado (verdadero / falso)',
      },

      'Blockly.Blocks.sensing__tilt_switch': {
        'Bot %1 %2 🔌Add-on Tilt Switch tilt-status (true/false)': 'Larva del moscardón %1 %2 🔌Add-en el interruptor de inclinación tilt-estado (verdadero / falso)',
      },

      'Blockly.Blocks.sensing__Pulse_Sensor': {
        'Bot %1 %2 🔌Add-on Pulse Sensor heart beat rates (per min)': 'Larva del moscardón %1 %2 🔌Add-pulso en las tasas de latidos del corazón del sensor (por minuto)',
      },

      'Blockly.Blocks.sensing__human_body_ir_sensor': {
        'Bot %1 %2 🔌Add-on PIR Motion Sensor active-status (true/false)': 'Larva del moscardón %1 %2 🔌Add-de sensor de movimiento PIR activo-estado (verdadero / falso)',
      },

      'Blockly.Blocks.sensing__Laser_Ranging_Sensor': {
        'Bot %1 %2 🔌Add-on Laser Ranging Sensor distance (mm)': 'Larva del moscardón %1 %2 🔌Add-en Laser Ranging distancia Sensor (mm)',
      },

      'Blockly.Blocks.sensing__read_3Axis_Digital_Compass_by_xyz': {
        'Bot %1 %2 🔌Add-on 3-Axis Digital Compass %3 gauss': 'Larva del moscardón %1 %2 🔌Add-en 3 ejes brújula digital %3 gauss',
      },

      'Blockly.Blocks.sensing__MPU6050_Accelerometer_and_Gyro': {
        'Bot %1 %2 🔌Add-on MPU6050 Accelerometer & Gyro %3': 'Larva del moscardón %1 %2 🔌Add-en MPU6050 acelerómetro y Gyro %3',
        'X Acceleration': 'X Aceleración',
        'Y Acceleration': 'Y Aceleración',
        'Z Acceleration': 'Z Aceleración',
        'X Gravity': 'X gravedad',
        'Y Gravity': 'Y gravedad',
        'Z Gravity': 'Z gravedad',
      },

      'Blockly.Blocks.events__irReceiver_Received': {
        'On bot %1 🔌Add-on IR Reciever triggered with IR-code %2': 'en bot %1 🔌Add-IR en Reciever desencadenó con IR-código %2',
      },

      'Blockly.Blocks.events__touch_sensor_touching': {
        'On bot %1 🔌Add-on Touch Sensor touching': 'en bot %1 🔌Add-al tacto del sensor táctil',
      },

      'Blockly.Blocks.events__button_pushed': {
        'On bot %1 🔌Add-on Button pressed': 'en bot %1 🔌Add-en el botón presionado',
      },

      'Blockly.Blocks.events__switch_on': {
        'On bot %1 🔌Add-on Switch on': 'en bot %1 🔌Add-en Encender',
      },

      'Blockly.Blocks.events__switch_off': {
        'On bot %1 🔌Add-on Switch off': 'en bot %1 🔌Add-en del interruptor',
      },

      'Blockly.Blocks.events__ir_reflective_sensor': {
        'On bot %1 🔌Add-on IR Reflective Sensor object detected': 'en bot %1 🔌Add-en el objeto sensor reflectante IR detectada',
      },

      'Blockly.Blocks.events__limit_switch': {
        'On bot %1 🔌Add-on Collision Sensor closed': 'en bot %1 🔌Add-en sensor de colisión cerrada',
      },

      'Blockly.Blocks.events__tilt_detected': {
        'On bot %1 🔌Add-on Tilt Switch tilt detected': 'en bot %1 🔌Add-en el interruptor de inclinación de inclinación detectado',
      },

      'Blockly.Blocks.events__human_body_ir_sensor': {
        'On bot %1 🔌Add-on PIR Motion Sensor active': 'en bot %1 🔌Add-de sensor de movimiento PIR activo',
      },

      'Blockly.Blocks.events__magnetic_field_detected': {
        'On bot %1 🔌Add-on Magnetic - Hall Sensor active': 'en bot %1 🔌Add-de Magnético - Sensor Hall activa',
      },

      'Blockly.Blocks.events__vibration_detected': {
        'On bot %1 🔌Add-on Vibration Sensor vibration detected': 'en bot %1 🔌Add-en vibración sensor de la vibración detectada',
      },

      'Blockly.Blocks.events__knock_detected': {
        'On bot %1 🔌Add-on Vibration Sensor knock detected': 'en bot %1 🔌Add-en sensor de la vibración golpe de la detecta',
      },

      'Blockly.Blocks.events__pulse_sensor_detected': {
        'On bot %1 🔌Add-on Pulse Sensor detecting finished': 'en bot %1 🔌Add-Sensor de detección de pulsos acabada',
      },

      'Blockly.Blocks.events__gesture_detected': {
        'On bot %1 🔌Add-on Gesture detected with gesture %2': 'en bot %1 🔌Add-en Gesto detectada con gesto %2',
      },

      'Blockly.Blocks.events__out_of_boundary': {
        'On bot %1 out of mat boundary': 'en bot %1 fuera de la vista de la cámara',
      },

      'Blockly.Blocks.control__wait': {
        '%1 wait %2 second(s)': '%1 espera %2 segundo(s)',
      },

      'Blockly.Blocks.sensing__value_of_datetime': {
        'current %1': 'corriente %1',
        'Year': 'Año',
        'Month': 'Mes',
        'Day': 'Día',
        'Hour': 'Hora',
        'Minute': 'Minuto',
        'Second': 'Segundo',
      },

      'Blockly.Blocks.sensing__current_second': {
        'current system time (seconds)': 'hora actual del sistema (segundos)',
      },

      'Blockly.Blocks.sensing__current_millisecond': {
        'Current system time (milliseconds)': 'hora actual del sistema (milisegundos)',
      },

      'Blockly.Blocks.log': {
        '%1 print %2 to log': '%1 imprimir %2 para iniciar sesión',
      },

      'Blockly.Blocks.customized_logic_totext': {
        '%1 to Text': '%1 convertir a texto',
      },

      'Blockly.Blocks.customized_controls_if': {
        '%{BKY_CONTROLS_IF_MSG_IF} %1': '%{BKY_CONTROLS_IF_MSG_IF} %1',
        '%{BKY_CONTROLS_IF_MSG_THEN} %1': '%{BKY_CONTROLS_IF_MSG_THEN} %1',
      },

      'Blockly.Blocks.customized_logic_compare': {
        '%1 %2 %3': '%1 %2 %3',
      },

      'Blockly.Blocks.sensing__objCompare': {
        '❔ object %1 equals to %2': 'objeto ❔ %1 igual a %2',
      },

      'Blockly.Blocks.output_logic__range': {
        'is %1 %2 Min. %3 Max. %4': 'es %1 %2 Min. %3 Max. %4',
        'inside': 'dentro',
        'outside': 'fuera de',
      },

      'Blockly.Blocks.customized_controls_whileUntil': {},
      'Blockly.Blocks.customized_controls_repeat_ext': {},
      'Blockly.Blocks.customized_controls_for': {},
      'Blockly.Blocks.customized_controls_flow_statements': {},

      'Blockly.Blocks.sensing__constant_angle': {
        'angle %1': 'ángulo %1',
      },

      'Blockly.Blocks.customized_logic_tonumber': {
        '%1 to Number': '%1 convertir a número',
      },

      'Blockly.Blocks.read__dice_value': {
        '%1 number of 🎲dice': '%1 número de dados🎲',
        'read': 'leer',
      },

      'Blockly.Blocks.array_declare': {
        '⛓ Declare an Array named: ': '⛓ declarar una matriz denominada:',
      },

      'Blockly.Blocks.array_set_item': {
        'Set element: %1 to value: %2 in array: %3': 'elemento de conjunto: %1 valorar: %2 en array: %3',
      },

      'Blockly.Blocks.array_get_item': {
        'Get element %1 from array variable: %2': 'Obtener elemento %1 de variable de matriz: %2',
      },

      'Blockly.Blocks.array_length': {
        'Get array %1 length': 'Obtener matriz %1 longitud',
      },

      'Blockly.Blocks.the3d__object_effect_onFire_frozen': {
        '🎆 Sprite %1 perform %2': '🎆 Sprite %1 realizar %2',
      },

      'Blockly.Blocks.the3d__object_effect_nil_effect': {
        '🎆 Sprite %1 perform effect': '🎆 Sprite %1 realizar efecto',
      },

      'Blockly.Blocks.the3d__object_effect_flying_height': {
        '🎆 Flying Sprite  %1 set radius to %2': '🎆 Flying Sprite %1 radio ajustado a %2',
      },

      'Blockly.Blocks.motion_angle__turn': {
        'Bot %1 turn %2': 'Larva del moscardón %1 giro %2',
        'left': 'izquierda',
        'right': 'derecho',
        'slight left': 'ligeramente a la izquierda',
        'slight right': 'ligeramente a la derecha',
        'left u-turn': 'izquierda cambio de sentido',
        'right u-turn': 'derecho de cambio de sentido',
      },

      'Blockly.Blocks.motion_servo__beginner__open_close_toggle': {
        'Bot %1 %2 gripper': 'Larva del moscardón %1 %2 pinza',
        'open': 'abierto',
        'close': 'cerca',
      },

      'Blockly.Blocks.motion_angle__look': {
        'Bot %1 look %2': 'Larva del moscardón %1 Mira %2',
        'Up/North': 'En el norte',
        'Right/East': 'Derecha / East',
        'Down/South': 'Hacia el sur',
        'Left/West': 'Izquierda / West',
      },

      'Blockly.Blocks.motion_move__move_to_XY': {
        'Bot %1 move to X: %2 Y: %3 cm': 'Larva del moscardón %1 mover a X: %2 Y: %3 cm',
      },

      'Blockly.Blocks.sensing__robotXYA': {
        'read %1 of Bot %2': 'leer %1 de Bot %2',
        'x (cm)': 'x (cm)',
        'y (cm)': 'y (cm)',
        'angle (°)': 'ángulo (°)',
      },

      'Blockly.Blocks.beginner__motion_mirror__follow': {
        'Bot %1 follow %2': 'Larva del moscardón %1 seguir %2',
      },

      'Blockly.Blocks.motion_special__grab_object': {
        'Bot %1 grab object at X: %2 cm Y: %3 cm': 'Larva del moscardón %1 agarrar objeto en X: %2 cm Y: %3 cm',
      },

      'Blockly.Blocks.motion_special__release_object': {
        'Bot %1 release object at X: %2 cm Y: %3 cm': 'Larva del moscardón %1 liberar objeto en X: %2 cm Y: %3 cm',
      },

      'Blockly.Blocks.beginner__motion_special__drive_in_circle': {
        'Bot %1 drive %2 in %3 circle': 'Larva del moscardón %1 manejar %2 en %3 circulo',
        'left': 'izquierda',
        'right': 'derecho',
        'Small': 'Pequeña',
        'Medium': 'Medio',
        'Large': 'Grande',
      },

      'Blockly.Blocks.beginner__play_song': {
        'Bot %1 play song %2': 'Larva del moscardón %1 reproducir la canción %2',
      },
      'Blockly.Blocks.leddiffuser_set_on_state': {
        'Bot %1 set Headlight on %2': 'Larva del moscardón %1 Linterna en el set %2',
      },
      'Blockly.Blocks.leddiffuser_set_color': {
        'Bot %1 set Headlight color to %2': 'Larva del moscardón %1 establecer el color del faro para %2',
      },
      'Blockly.Blocks.beginner__LED_turn_onOff': {
        'Bot %1 🔌Add-on LED %2': 'Larva del moscardón %1 🔌Add-en LED %2',
        'On': 'En',
        'Off': 'Apagado',
      },
      'Blockly.Blocks.sight_led_strip_4_colors': {
        'Bot %1 🔌Add-on RGB-LED %2 %3 %4 %5': 'Larva del moscardón %1 -En 🔌Add RGB LED %2 %3 %4 %5',
      },
      'Blockly.Blocks.beginner__sight_led_matrix_display__show': {
        'Bot %1 🔌Add-on LED Matrix, display symbol %2': 'Larva del moscardón %1 🔌Add-en matriz LED, símbolo de la pantalla %2',
      },
      'Blockly.Blocks.beginner__sight_led_matrix_display__set_text': {
        'Bot %1 🔌Add-on LED Matrix, display text %2': 'Larva del moscardón %1 🔌Add-Matriz de LED, pantalla de texto %2',
      },
      'Blockly.Blocks.beginner__events__button_pushed': {
        'When bot %1 button pushed': 'cuando bot %1 botón pulsado',
      },
      'Blockly.Blocks.beginner__events__out_of_boundary': {
        'When bot %1 drives off mat': 'cuando bot %1 se marcha estera',
      },
      'Blockly.Blocks.beginner_math_arithmetic': {},
      'Blockly.Blocks.beginner__sensing__ultrasonic_distance_sensor': {
        'Bot %1 %2 Distance Sensor': 'Larva del moscardón %1 %2 sensor de distancia',
      },
      'func:getSyncModeArg': {
        'synchronously': 'sincrónicamente',
        'asynchronously': 'de forma asíncrona',
      },
      'Blockly.Blocks.motion_move__park': {
        'Bot %1 park in slot %2 %3': 'Larva del moscardón %1 parque en la ranura %2 %3',
      },
      'Blockly.Blocks.sensing__mapMarkXY': {
        'read %1 of map mark position (cm)': 'leer %1 de posición del mapa marca (cm)',
      },
      'Blockly.Blocks.motion_move__move_to_XY_with_self_adjustment': {
        'Bot %1 move to X: %2 Y: %3 cm ⇨ with accurate adjustments': 'Larva del moscardón %1 mover a X: %2 Y: %3 ⇨ cm con los ajustes precisos',
      },
      'Blockly.Blocks.motion_move__move_to_XY_stop_distance_with_self_adjustment': {
        'Bot %1 move to X: %2 Y: %3 cm ⇨ stop at distance %4 cm ⇨ with accurate adjustments': 'Larva del moscardón %1 mover a X: %2 Y: %3 cm ⇨ parada en distancia %4 ⇨ cm con los ajustes precisos',
      },
      'Blockly.Blocks.motion_mirror__follow': {
        'Bot %1 follow bot %2 by %3 cm away': 'Larva del moscardón %1 siga bot %2 por %3 cm de distancia',
      },
      'Blockly.Blocks.motion_special__grab_object_by_halfway': {
        'Bot %1 grab object at X: %2 cm Y: %3 cm by Halfway': 'Larva del moscardón %1 agarrar objeto en X: %2 cm Y: %3 cm por medio camino',
      },
      'Blockly.Blocks.motion_special__oval': {
        'Bot %1 oval (diameter X %2 cm Y %3 cm) %4': 'Larva del moscardón %1 ovalada (diámetro X %2 cm Y %3 cm) %4',
        'Left': 'Izquierda',
        'Right': 'Correcto',
      },
      'Blockly.Blocks.beginner__headlights_set_colorObj': {
        'Bot %1 Headlights %2': 'Larva del moscardón %1 faros %2',
      },
      'Blockly.Blocks.sensing__led__read_whether_on': {
        'Bot %1 %2 🔌Add-on LED On-status (Boolean)': 'Larva del moscardón %1 %2 -En 🔌Add LED On-estado (Boolean)',
      },
      'Blockly.Blocks.sight_led__switch': {
        'Bot %1 %2 🔌Add-on LED On-status %3': 'Larva del moscardón %1 %2 🔌Add-LED On-status %3',
      },
      'Blockly.Blocks.sensing__rgb_color': {
        'Bot %1 read RGB-LED color at position %2': 'Larva del moscardón %1 leer de color RGB-LED en la posición %2',
      },
      'Blockly.Blocks.sensing__tube_display': {
        'Bot %1 read 4digit display %2': 'Larva del moscardón %1 leer pantalla de 4 dígitos %2',
        'minutes': 'minutos',
        'seconds': 'segundos',
      },
      'Blockly.Blocks.sight_led__color': {
        'LED Colour: %1': 'LED Color: %1',
      },
      'Blockly.Blocks.sight_led_strip__light': {
        'Bot %1 RGB-LED position #%2 by intensity %3 %% color %4': 'Larva del moscardón %1 posición RGB-LED ##%2 por la intensidad %3 %% color %4',
      },
      'Blockly.Blocks.sight_led_strip__light_off': {
        'Bot %1 RGB-LED position %2 off': 'Larva del moscardón %1 posición RGB-LED %2 apagado',
      },
      'Blockly.Blocks.sight_tube_display__show': {
        'Bot %1 show %2:%3 on 4digit display': 'Larva del moscardón %1 show %2:%3 en la pantalla de 4 dígitos',
      },
      'Blockly.Blocks.sendCommand': {
        '%1 Send %2 command %3 with params %4': '%1 Enviar %2 mando %3 con params %4',
      },
      'Blockly.Blocks.is_robot_position_in_the_area': {
        'is Bot %1 at %2 within %3 CM': 'es Bot %1 a %2 dentro %3 CM',
      },
    },

    // ===studentToolBox===
    'studentToolbox': {
      'advanced.xml': {
        'Motion 🚗': 'Movimiento 🚗',
        'Free Movement': 'Movimiento libre',
        'Mat Movement 🏃': 'Movimiento estera 🏃',
        'AI Navigation 💡': 'AI navegación 💡',
        'Position adjustment 🚐': 'Ajuste de la posición 🚐',
        'Interactions 🤝': 'interacciones 🤝',
        'Special': 'Especial',
        'Bits 💡': 'Los bits 💡',
        'On-board ⛵': 'A bordo ⛵',
        'Sound 🙊': 'Sonido 🙊',
        'Sight 🙈': 'vista 🙈',
        'Environmental ⛅': '⛅ ambiental',
        'Action 🍭': 'acción 🍭',
        '3D Effects 🎆': 'Efectos 3D 🎆',
        'Events 🎈': 'eventos 🎈',
        'Sensor Events': 'sensor Events',
        'System Events': 'Sucesos del sistema',
        'Timing ⌛️': 'Sincronización ⌛️',
        'Print 📝': 'Impresión 📝',
        'Logic 🤔': 'lógica 🤔',
        'Loops 💈': 'bucles 💈',
        'Functions 💻': 'funciones 💻',
        'Math 🤯': 'matemáticas 🤯',
        'Arrays ⛓': 'Las matrices ⛓',
        'Variables 🤹': 'Variables 🤹',
        '💐 Local variables': 'Las variables locales 💐',
        '🏫 Classroom variables': '🏫 variables de aula',
        'Create variable...': 'Crear medidas de ...',
        'RGB Color': 'color RGB',
        'Color Sensor': 'sensor de color',
        'Single LED': 'solo LED',
        'RGB-LED': 'RGB-LED',
        '4-digit Display': 'Display 4 dígitos',
        '8x8 LED Matrix': 'Matrix 8x8 LED',
      },
      'beginner.xml': {
        'Motion 🚗': 'Movimiento 🚗',
        'Free Movement': 'Movimiento libre',
        'Mat Movement 🏃': 'Movimiento estera 🏃',
        'Special': 'Especial',
        'Bits 💡': 'Los bits 💡',
        'Sound 🙊': 'Sonido 🙊',
        'Sight 🙈': 'vista 🙈',
        'Events 🎈': 'eventos 🎈',
        'Timing ⌛️': 'Sincronización ⌛️',
        'Print 📝': 'Impresión 📝',
        'Loops 💈': 'bucles 💈',
        'Math 🤯': 'Matemáticas 🤯',
        'Sensing 📐': 'Sintiendo 📐',
      },
    },
    // ===teacherToolBox===
    'teacherToolbox': {
      'advanced.xml': {
        'Motion 🚗': 'Movimiento 🚗',
        'Free Movement': 'Movimiento libre',
        'Mat Movement 🏃': 'Movimiento estera 🏃',
        'AI Navigation 💡': 'AI navegación 💡',
        'Position adjustment 🚐': 'Ajuste de la posición 🚐',
        'Interactions 🤝': 'interacciones 🤝',
        'Special': 'Especial',
        'Bits 💡': 'Los bits 💡',
        'On-board ⛵': 'A bordo ⛵',
        'Sound 🙊': 'Sonido 🙊',
        'Sight 🙈': 'vista 🙈',
        'Environmental ⛅': '⛅ ambiental',
        'Action 🍭': 'acción 🍭',
        '3D Effects 🎆': 'Efectos 3D 🎆',
        'Events 🎈': 'eventos 🎈',
        'Sensor Events': 'sensor Events',
        'System Events': 'Sucesos del sistema',
        'Timing ⌛️': 'Sincronización ⌛️',
        'Print 📝': 'Impresión 📝',
        'Logic 🤔': 'lógica 🤔',
        'Loops 💈': 'bucles 💈',
        'Functions 💻': 'funciones 💻',
        'Math 🤯': 'matemáticas 🤯',
        'Arrays ⛓': 'Las matrices ⛓',
        'Variables 🤹': 'Variables 🤹',
        '💐 Local variables': 'Las variables locales 💐',
        '🏫 Classroom variables': '🏫 variables de aula',
        'Create variable...': 'Crear medidas de ...',
        'RGB Color': 'color RGB',
        'Color Sensor': 'sensor de color',
        'Single LED': 'solo LED',
        'RGB-LED': 'RGB-LED',
        '4-digit Display': 'Display 4 dígitos',
        '8x8 LED Matrix': 'Matrix 8x8 LED',
      },
      'beginner.xml': {
        'Motion 🚗': 'Movimiento 🚗',
        'Free Movement': 'Movimiento libre',
        'Mat Movement 🏃': 'Movimiento estera 🏃',
        'Special': 'Especial',
        'Bits 💡': 'Los bits 💡',
        'Sound 🙊': 'Sonido 🙊',
        'Sight 🙈': 'vista 🙈',
        'Events 🎈': 'eventos 🎈',
        'Timing ⌛️': 'Sincronización ⌛️',
        'Print 📝': 'Impresión 📝',
        'Loops 💈': 'bucles 💈',
        'Math 🤯': 'Matemáticas 🤯',
        'Sensing 📐': 'Sintiendo 📐',
      },
    },
    // ===workspaces/basic/index.js===
    'workspaces/basic/index.js': {
      'WARNING': 'ADVERTENCIA',
      'Block ID': 'Bloquear ID',
    },

    // ===codeChecker.js===
    'codeChecker.js': {
      'First configure the sensor for this robot.': 'En primer lugar configurar el sensor para este robot.',
      'Robot {{robotId}} is not exist!': 'Robot {{robotId}} ¡no existe!',
      'The Robot 🤖️ {{robotIdentifier}} is offline.': 'El robot 🤖️ {{robotIdentifier}} está desconectado.',
    },

    // ===ClassRoom/index.jsx===
    'ClassRoom/index.jsx': {
      'Oops! Error on obtain classroom info.': '¡Huy! Error en obtener información aula.',
      'Are you sure?': '¿Estás seguro?',
      'Your code is running, do you want to stop it?': 'Su código se ejecuta, ¿quieres parar?',
      'Yes': 'Sí',
      'Oops!!  Fix errors highlighted in yellow blocks marked with "⚠️"': 'Vaya !! Corregir los errores resaltados en bloques amarillos marcados con "⚠️"',
      'Program error': 'error del programa',
      'Oops! - AI Navigation did not find a pathway to get to the desination.': '¡Huy! - AI navegación No se encontró una vía para llegar a la desination.',
    },

    // ===sensor_specs.js===
    'sensor_specs.js': {
      'Gripper': 'pinza',
      'Open': 'Abierto',
      'Closed': 'Cerrado',

      'Bright LED': 'LED brillante',
      'RGB-LED': 'RGB-LED',
      'buzzer': 'zumbador',

      'IR transmitter': 'transmisor de infrarrojos',
      'Relay': 'Relé',
      'On': 'En',
      'Off': 'Apagado',

      'Ultrasonic Distance Sensor': 'La distancia del sensor ultrasónico',

      'Waterproof Temperature Sensor': 'Sensor de temperatura impermeable',

      'humidity sensor': 'sensor de humedad',
      'Temp & Humidity Sensor': 'Temperatura y sensor de humedad',
      'Temperature': 'Temperatura',
      'Humidity': 'Humedad',

      'IR receiver': 'receptor IR',

      'push button': 'presionar el botón',
      'Push Button': 'presionar el botón',

      'magnetic sensor': 'sensor magnético',
      'Magnetic Sensor': 'sensor magnético',

      'vibration sensor': 'sensor de vibración',

      'Tilt Switch': 'Interruptor de inclinación',

      'knock sensor': 'sensor de detonacion',

      'Touch Sensor': 'Sensor tactil',
      'Touching': 'Conmovedor',
      'Idle': 'Ocioso',

      'sound sensor': 'sensor de sonido',

      'PIR Motion Sensor': 'Sensor de movimiento PIR',
      'Triggered': 'desencadenada',
      // 'Idle': 'Ocioso',

      'limit switch': 'límite de cambio',

      'Switch': 'Cambiar',

      'IR Reflective Sensor': 'Sensor reflectante IR',
      'Reflection detected': 'reflexión detectado',
      'No reflection': 'Sin reflejo',

      'Rotary Angel Sensor': 'Sensor Ángel rotativa',
      'degree': 'la licenciatura',
      'Angle': 'Ángulo',

      'Moisture Sensor': 'Sensor de humedad',
      'Moisture ratio': 'relación de humedad',

      'Light Sensor': 'Sensor de luz',
      'Brightness': 'Brillo',

      'steam sensor': 'sensor de vapor',

      'Water Sensor': 'sensor de agua',
      'Water index': 'índice agua',

      'Air Quality Sensor': 'Sensor de calidad del aire',
      'Air pollution index': 'índice de contaminación del aire',

      'Sound Sensor': 'sensor de sonido',
      'Sound level': 'Nivel de sonido',

      'Linear Potentiometer': 'El potenciómetro lineal',
      'Slide position index': 'índice de posición Slide',

      'UV Sensor': 'sensor UV',
      'UV strength': 'fuerza UV',

      'Pulse Sensor': 'sensor de pulso',
      'Pulse level': 'nivel de impulsos',

      'PH Sensor': 'sensor de pH',
      'PH index': 'índice de PH',

      'MEMS Microphone': 'MEMS micrófono',
      // 'Sound level': 'Nivel de sonido',

      '3-Axis Digital Compass': '3 ejes brújula digital',

      'Gauss': 'Gauss',

      'MPU6050 Accelerometer & Gyro': 'MPU6050 acelerómetro y Gyro',
      'acceleration': 'aceleración',
      'gravity': 'gravedad',

      'Color Sensor': 'sensor de color',
      'Color': 'Color',

      'Laser Ranging Sensor': 'Sensor láser Ranging',
      'Distance': 'Distancia',

      '4-Digit Display': '4-Digit Display',
      'Number': 'Número',

      'LED Matrix': 'Matriz de LED',

      'I2C EEPROM': 'EEPROM I2C',
      'Text': 'Texto',

      'Weight Sensor': 'sensor de peso',
      'Gram': 'Gramo',

      'BME280 Atmospheric Sensor': 'Sensor atmosférica BME280',
      'Barometric pressure': 'Presión barométrica',
      // 'Humidity': 'Humedad',
      // 'Temperature': 'Temperatura',
      'Barometric': 'Barométrico',

      'BMP180 Barometer': 'Barómetro BMP180',
    },

    // ===src/routes/ClassRoomRobots/components/Menu.jsx===
    'src/routes/ClassRoomRobots/components/Menu.jsx': {
      'Views': 'Puntos de vista',
      'Robots': 'Robots',
    },

    // ===ListMenu.jsx===
    'ListMenu.jsx': {
      'Are you sure?': '¿Estás seguro?',
      [`You won't be able to revert back!`]: 'Usted no será capaz de volver!',
      'Yes, delete it!': 'Sí, estoy seguro!',
      'Print': 'Impresión',
      'Remove': 'retirar',
      'Add Student': 'Añadir Estudiante',
      'Save Changes': 'Guardar cambios',
    },

    'StudentCard.jsx': {
      'Name...': 'Nombre...',
    },

    'StudentRow.jsx': {
      'Oop! Student {{studentName}} already exists': 'Oop! Estudiante {{studentName}} ya existe',
      'Name...': 'Nombre...',
      'Select robot...': 'Elija un robot ...',
    },

    'src/routes/ClassRoomStudents/components/TopMenu.jsx': {
      'Students': 'estudiantes',
    },

    'ClassRoomStudent/index.jsx': {
      'Oop! Error on loading classroom': 'Oop! Error en el aula oading',
      'Student names saved.': 'nombres de los estudiantes guardan.',
      'Print student login cards?': 'Imprimir tarjetas de estudiante claves?',
      'No': 'No',
      'Yes': 'Sí',
      'Oops!! Some students already added to this classroom': 'Vaya !! Algunos estudiantes ya se añaden a esta clase',
      'Error on saving students': 'Error en los estudiantes de ahorro',
      'Error with the import file': 'Error con el archivo de importación',
      'Oops...': 'Uy ...',
      'Error on adding student': 'Error en la adición de estudiante',
      'Add students to classroom': 'Añadir a los estudiantes del aula',
      'You have two options': 'Tiene dos opciones',
      'Upload list of student names.': 'lista de los nombres de los estudiantes cargar.',
      'Download template': 'Descargar plantilla',
      'Select file': 'Seleccione Archivo',
      'Add list of student names below.': 'Añadir lista de los nombres de los estudiantes de abajo.',
      'Import': 'Importar',
      'Print Student Login Cards': 'tarjetas de acceso de los estudiantes de impresión',
    },

    // ===Home/index.jsx===
    'Home/index.jsx': {
      [`I'm a`]: 'Soy un',
      'TEACHER': 'PROFESOR',
      'STUDENT': 'ESTUDIANTE',
    },

    // ===RecoveryPassword/Breadcrumb.jsx===
    'RecoveryPassword/Breadcrumb.jsx': {
      'Recovery password': 'contraseña de recuperación',
      [`I'm`]: 'soy',
    },

    // ===RecoveryPassword/index.js===
    'RecoveryPassword/index.js': {
      'I remember': 'recuerdo',
      'Recover': 'Recuperar',
    },

    // ===StudentLogin/Breadcrumb.jsx===
    'StudentLogin/Breadcrumb.jsx': {
      [`I'm a student`]: 'soy un estudiante',
      'Login': 'Iniciar sesión',
    },

    // ===StudentLogin/index.jsx===
    'StudentLogin/index.jsx': {
      'Oops!!': '¡Uy!',
    },
    // ===StudentRegister/Breadcrumb.jsx===
    'StudentRegister/Breadcrumb.jsx': {
      [`I'm a student`]: 'soy un estudiante',
      'Register': 'Registro',
    },

    // ===StudentRegister/index.jsx===
    'StudentRegister/index.jsx': {
      'Error on register': 'Error en el registro',
      'Hi student ...': 'estudiante hi ...',
      'Login': 'Iniciar sesión',
      'Your username': 'Su nombre de usuario',
      'Password': 'Contraseña',
      'Classroom ID': 'ID del aula',
    },

    'src/routes/StudentRobots/components/Menu.jsx': {
      'Robots': 'Robots',
    },

    'src/routes/StudentRobots/components/Robot.jsx': {
      'Sensors': 'Sensores',
    },

    'components/SensorsConfiguration.jsx': {
      'func:getSensors1': {
        '(none)': '(ninguna)',
        'Piranha LED': 'Piranha LED',
        'RGB LED array': 'array RGB LED',
        'Buzzer': 'Zumbador',
        'IR transmitter': 'transmisor de infrarrojos',
        'Relay': 'Relé',
        'Temperature': 'Temperatura',
        'Temperature & humidity': 'Temperatura y humedad',
        'IR receiver': 'receptor IR',
        'Push button': 'Presionar el botón',
        'Magnetic sensor': 'Sensor magnético',
        'Vibration sensor': 'Sensor de vibración',
        'Tilt Sensor': 'Sensor de inclinación',
        'Knock sensor': 'Sensor de detonacion',
        'Touch sensor': 'Sensor tactil',
        'Sound sensor': 'sensor de sonido',
        'Human Body Infrared Sensor': 'Sensor de infrarrojos del cuerpo humano',
        'Limit switch': 'Límite de cambio',
        'Opto-coupler': 'Optoacoplador',
      },
      'func:getSensors2': {
        '(none)': '(ninguna)',
        'Compass': 'Brújula',
        'Accelerometer': 'acelerómetro',
        'Gyroscope': 'Giroscopio',
        'OLED screen': 'OLED de pantalla',
      },
      'func:getSensors3': {
        '(none)': '(ninguna)',
        'Rotation sensor': 'sensor de rotación',
        'Moisture sensor': 'Sensor de humedad',
        'Photo resistor': 'resistencia de la foto',
        'Steam sensor': 'sensor de vapor',
        'Water level sensor': 'sensor de nivel de agua',
        'Smog senser': 'senser el smog',
        'Alcohol sensor': 'sensor de alcohol',
        'Light sensor': 'Sensor de luz',
        'UV rays sensor': 'sensor de rayos UV',
        'IR obstacle sensor': 'Detector de obstáculos IR',
      },
      'func:getSensors4': {
        '(none)': '(ninguna)',
        'Servo': 'servo',
      },
      'func:getSensors5': {
        '(none)': '(ninguna)',
        'Distance sensor': 'sensor de distancia',
      },
      'Ops.. something went wrong': 'Ops .. algo salió mal',
    },

    // ===StudentRobots/StudentRobots.jsx===
    'StudentRobots/StudentRobots.jsx': {
      'Oops! Error on loading classroom: {{classroomName}}': '¡Huy! Error en el aula de carga: {{classroomName}}',
    },

    // ===components/ClassRoomItem.jsx===
    'components/ClassRoomItem.jsx': {
      [`Scan the class code with the "Kai's Eye" phone`]: `Escanear el código de la clase con el teléfono "Ojo de Kai"`,
      'or': 'o',
      [`using with the "Kai's Virtual Viewer" found in the app store.`]: 'usando el "Visor de Kai virtual" que se encuentra en la tienda de aplicaciones.',
      'Are you sure?': '¿Estás seguro?',
      [`You won't be able to recover this! {{itemName}} will be lost.`]: `Usted no será capaz de recuperar esto! {{itemName}} se perderá.`,
      'Yes, delete it!': 'Sí, estoy seguro!',
      'No, cancel!': 'No, cancelar!',
      'No students loaded yet': 'Sin embargo los estudiantes cargados',
      'Removing classroom': 'Extracción del aula',
      'Back to your classroom': 'Volver a su salón de clases',
      'Join': 'Unirse',
      [`Scan with Kai's Eye phone app`]: 'Escanear con aplicación de teléfono de los ojos de Kai',
      'Created': 'Creado',
    },

    'components/NewClassRoom.jsx': {
      'Create a new classroom': 'Crea un nuevo salón de clases',
      'Class name (required)': 'nombre de la clase (requerido)',
      'Request failed': 'Solicitud fallida',
      [`Scan the class code with the "Kai's Eye" phone`]: `Escanear el código de la clase con el teléfono "Ojo de Kai"`,
      'or': 'o',
      [`using with the "Kai's Virtual Viewer" found in the app store.`]: `usando el "Visor de Kai virtual" que se encuentra en la tienda de aplicaciones.`,
    },

    // ===SwitchClassroom/index.jsx===
    'SwitchClassroom/index.jsx': {
      'Classrooms': 'Aulas',
      'Oops! Error on get classes': '¡Huy! Error en las clases Get',
      'Oops! Error on joining class {{classroomId}}': '¡Huy! Error en la clase de unirse {{classroomId}}',
      'Join a classroom to get started': 'Unirse a un salón de clases para empezar',
      'Loading classrooms': 'Cargando aulas',
    },

    // ===TeacherLogin/Breadcrumb.jsx===
    'TeacherLogin/Breadcrumb.jsx': {
      [`I'm a teacher`]: 'Soy un profesor',
      'login': 'Iniciar sesión',
    },

    // ===TeacherLogin/index.jsx===
    'TeacherLogin/index.jsx': {
      'Register': 'Registro',
      'Password': 'Contraseña',
      'I forgot my password': 'Olvidé mi contraseña',
      'E-mail': 'E-mail',
    },

    // ===TeacherRegister/Breadcrumb.jsx===
    'TeacherRegister/Breadcrumb.jsx': {
      [`I'm a teacher`]: 'Soy un profesor',
      'Register': 'Registro',
    },

    // ===TeacherRegister/index.jsx===
    'TeacherRegister/index.jsx': {
      'Error on register': 'Error en el registro',
      'Hi teacher ...': 'Hola profesor ...',
      'Your name': 'Tu nombre',
      'Password': 'Contraseña',
      'Woo Hoo - nearly there!': 'Woo Hoo - casi allí!',
      'Please check the verification email in your inbox or spam folder.': 'Por favor, compruebe el mensaje de verificación en tu bandeja de entrada o carpeta de correo no deseado.',
      [`If you don't receive a verification email, contact <a href="mailto:support@kaisclan.ai">support@kaisclan.ai</a>`]: 'Si no recibe un correo electrónico de verificación, el contacto <a href="mailto:support@kaisclan.ai">support@kaisclan.ai</a>',
    },

    // ===TeacherReset/Breadcrumb.jsx===
    'TeacherReset/Breadcrumb.jsx': {
      [`I'm a teacher`]: 'Soy un profesor',
      'Reset password': 'Restablecer la contraseña',
    },

    // ===TeacherReset/index.jsx===
    'TeacherReset/index.jsx': {
      'New Password': 'Nueva contraseña',
      'I remember': 'recuerdo',
      'Reset': 'Reiniciar',
    },

    // ===UserVerify/index.jsx===
    'UserVerify/index.jsx': {
      'Verifying...': 'Verificación de...',
      [`Woo hoo!! Kai's Clan account has now been verified`]: 'Woo hoo !! cuenta clan de Kai ahora se ha verificado',
      'Click here to login': 'Haga clic aquí para ingresar',
    },

    // ===WebGL/SeparateWindow/index.jsx===
    'WebGL/SeparateWindow/index.jsx': {
      'Click to load 3D virtual environment.': 'Haga clic para cargar entorno virtual 3D.',
    },

    // ===store/models/Project.js===
    'store/models/Project.js': {
      'Junior': 'Júnior',
      'Intermediate': 'Intermedio',
      'Advanced': 'Avanzado',
      'Expert': 'Experto',
    },

    // ===store/classRoomStore.js===
    'store/classRoomStore.js': {
      'You are Admin, save successful.': 'Estás administración, guarda éxito.',
      'Error on saving your new work': 'Error al guardar tu nuevo trabajo',
      'Oops!! Save failed, you are not the owner of this project.': 'Vaya !! Guardar falló, usted no es el propietario de este proyecto.',
      [`Save this as your project so you don't loose any work`]: 'Guardar como su proyecto para no perder ningún trabajo',
      'Enter a new classroom variable name': 'Introduzca un nuevo nombre de la variable aula',
      'Enter a new classroom variable value': 'Introduzca un nuevo valor de la variable aula',
      'Read Only?': '¿Solo lectura?',
      'New classroom variable {{variableName}} saved': 'Nueva variable aula {{variableName}} salvado',
      'Robot  {{robotId}} does not exist in this class room': 'Robot {{robotId}} no existe en esta sala de clases',
      [`This robot's sensors register state is {{state}} now, you can not send another request in this situation.`]: 'Los sensores de este robot registro de estado es {{state}} Ahora, no se puede enviar otra solicitud en esta situación.',
      'Oops! Something went wrong - Response data from robot does not match with request data.': '¡Uy! Algo salió mal: los datos de respuesta del robot no coinciden con los datos de la solicitud.',
      'Please check Robot {{robotId}} if online.': 'Compruebe robot {{robotId}} está en linea.',
    },

    // ===store/teacherRecoveryStore.js===
    'store/teacherRecoveryStore.js': {
      'Please, check the errors bellow.': 'Por favor, compruebe los errores de abajo.',
      'Please, check your e-mail.': 'Por favor revise su correo electrónico.',
    },

    // ===store/teacherResetStore.js===
    'store/teacherResetStore.js': {
      'Please, check the errors bellow.': 'Por favor, compruebe los errores de abajo.',
    },

    // ===store/userMediaStore.js===
    'store/userMediaStore.js': {
      'Files need to be smaller that 1MB': 'Los archivos tienen que ser menor que 1 MB',
    },

    // ===store/userStore.js===
    'store/userStore.js': {
      'Please, check your e-mail.': 'Por favor revise su correo electrónico.',
    },

    // ===utils/index.js===
    'utils/index.js': {
      'The interval has alreadly stopped.': 'El intervalo se ha detenido alreadly.',
    },

    // ===src/components/QRScanner/index.jsx===
    'src/components/QRScanner/index.jsx': {
      'Allow your browser to access your camera or login on the right.': 'Permitir que su navegador para acceder a la cámara o de inicio de sesión a la derecha',
    },

    // ===src/routes/QR/index.jsx===
    'src/routes/QR/index.jsx': {
      'Redirecting...': 'redirigiendo...',
    },

    // ===src/routes/StudentLogin/StudentLoginForm.jsx===
    'src/routes/StudentLogin/StudentLoginForm.jsx': {
      'Check you entered your username and classroom code correctly.': 'Y comprueba que ha introducido su código de usuario y aula correctamente.',
      'QR Code Login': 'Código QR de sesión',
      [`Don't have a QR code?`]: 'No tener un código QR?',
      'Ask your teacher to print it out for you.': 'Pregunte a su profesor para imprimirlo para usted.',
      'Login': 'Iniciar sesión.',
      [`Don't have an account?`]: '¿No tienes una cuenta?',
      'Ask your teacher to create one for you.': 'Pregunte a su profesor para crear una para usted.',
      'Oops! Error with login, try again.': '¡Huy! Error con el inicio de sesión, inténtelo de nuevo.',
    },

    // src/routes/ClassRoomStudentsLoginCards/index.jsx
    'src/routes/ClassRoomStudentsLoginCards/index.jsx': {
      'Error on loading classroom': 'Error en el aula de carga',
      'PRINT': 'IMPRESIÓN',
      'Print Login Cards': 'Imprimir tarjetas de sesión',
    },

    // ===src/routes/ClassRoomStudentsLoginCards/components/TopMenu.jsx===
    'src/routes/ClassRoomStudentsLoginCards/components/TopMenu.jsx': {
      'Views': 'Puntos de vista',
      'Login Card': 'Inicio de sesión de tarjeta',
    },

    // ===src/routes/ClassRoomStudentsLoginCards/StudentLoginCard/index.jsx===
    'src/routes/ClassRoomStudentsLoginCards/StudentLoginCard/index.jsx': {
      'Go to': 'Ir',
      'Login with your name and code': 'Iniciar sesión con su nombre y el código',
      'Scan the QR code above': 'Escanear el código QR anteriormente',
    },

    // ===src/routes/ClassRoom/components/ProjectList/ProjectRule.jsx===
    'src/routes/ClassRoom/components/ProjectList/ProjectRule.jsx': {
      'Are you sure?': '¿Estás seguro?',
      [`You won't be able to recover this! {{ruleName}} will be lost.`]: 'Usted no será capaz de recuperar esto! {{ruleName}} se perderá.',
      'Yes, delete it!': 'Sí, estoy seguro!',
      'No, cancel!': 'No, cancelar!',
    },

    // ===map_specs.js===
    'map_specs.js': {
      'func:onFireFrozenEffectThingFactory': {
        'OnFire': 'En llamas',
        'Frozen': 'Congelado',
      },
      'Create': 'Crear',
      'Smart city': 'Ciudad inteligente',
      'Project life stream': 'Ciclo de vida del proyecto', // Spanish = Project life cycle
      'Rescue run': 'descubrimiento de Marte',
      'Automated warehouse': 'Almacén automatizado',
      'Mars discovery': 'Descubrimiento de Marte',
    },
    'NewStudentConfigurationItem.jsx': {
      '🚀 Shortcut: Copy then paste the student list from Excel to here.': '🚀 Atajo: copie y pegue la lista de estudiantes desde Excel hasta aquí.',
      '🚀Type a student name(Do not use the real name) and press Enter': '🚀 Escriba el nombre de un alumno (no use el nombre real) y presione Entrar',
    },
    'ViewsStudentConfiguration.jsx': {
      'Student names saved.': 'Nombres de estudiantes guardados.',
      'Print student login cards?': 'Imprimir tarjetas de inicio de sesión de estudiantes?',
      'No': 'No',
      'Yes': 'si',
      'Oops!! Some students already added to this classroom': '¡Uy! Algunos estudiantes ya se agregaron a este aula.',
      'Error on saving students': 'Error al salvar estudiantes',
      'Are you sure?': '¿Estás seguro?',
      [`You won't be able to revert back!`]: '¡No podrás volver atrás!',
      'Yes, delete it!': 'Sí, bórralo!',
      'Students': 'Estudiantes',
      'Print Student Login Cards': 'Imprimir tarjetas de inicio de sesión del estudiante',
      'Save changes': 'Guardar cambios',
      'Remove': 'Eliminar',
    },
    'ViewsRobotConfiguration.jsx': {
      'Empty this slot': `Vaciar esta ranura`,
      'Cancel': `Cancelar`,
      'Save': `salvar`,
      'Search sensors...': `Buscar sensores ...`,
      'Robot': `Robot`,
      'No Sensor be found!': `¡No se encuentra ningún sensor!`,
      'Unselect': `Deseleccionar`,
      'Saved': 'Salvado',
      'Oops... no robots found!': 'Uy ... no hay robots encontrado!',
      'Please add at least one robot': 'Por favor, añadir al menos un robot',
      'Robot sensor bits': 'Puntas de sensor de robot',
      'Robot Offline': 'Robot fuera de línea',
    },
    'CollapseButtonGroup/index.jsx': {
      'Are you sure?': '¿Estás seguro?',
      [`Your changes will be lost if you don't save them.`]: 'Sus cambios se perderán si no los guarda.',
      [`Don't Save`]: 'No guardar',
      'Cancel': 'Cancelar',
    },
  },
});
